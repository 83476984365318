import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import {GroundProjectedSkybox} from 'three/addons/objects/GroundProjectedSkybox.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { gsap } from 'gsap'
import * as dat from 'lil-gui'

const gui = new dat.GUI()
let numberMap = {
    mapnum:0

}

const loadingBarElement = document.querySelector('.loading-bar')
let sceneReady = false
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        // Wait a little
        window.setTimeout(() =>
        {
            // Animate overlay
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

            // Update loadingBarElement
            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''
        }, 500)

        window.setTimeout(() =>
        {   
            scene.background = environmentMap[0]
            sceneReady = true
        }, 2000)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        // Calculate the progress and update the loadingBarElement
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
)
const dracoLoader = new DRACOLoader(loadingManager)
dracoLoader.setDecoderPath('/draco/')
const gltfLoader = new GLTFLoader(loadingManager)
const fbxLoader = new FBXLoader(loadingManager)
const hdriLoader = new RGBELoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)

/**
 * Base
 */
// Debug
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    // wireframe: true,
    transparent: true,
    uniforms:
    {
        uAlpha: { value: 1 }
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
overlay.position.set(0,2.5,0)
scene.add(overlay)



/*
UPDATE MATERIALS & BACKGROUND
*/ 
const updateAllMaterials = ()=>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
             // child.material.envMap = environmentMap
             child.material.envMapIntensity = debugObject.envMapIntensity
             scene.backgroundIntensity = debugObject.backgroundIntensity
             scene.backgroundBlurriness= debugObject.backgroundBlurriness
             child.material.needsUpdate = true
             child.castShadow = true
             child.receiveShadow = true
        }
    })
}
let skybox =null
   
const UpdateBackground= ()=>{
    // environmentMap[numberMap['mapnum']].encoding = THREE.sRGBEncoding
    scene.remove(skybox)
    scene.background= environmentMap[numberMap['mapnum']]
    scene.environment = environmentMap[numberMap['mapnum']]
    if(numberMap['mapnum']==2||numberMap['mapnum']==3){ 
        skybox = new GroundProjectedSkybox(environmentMap[numberMap['mapnum']])
        skybox.scale.setScalar(50)
        skybox.radius= 100
        skybox.height = 10
        gui.add(skybox, 'radius',1,200,0.1).name('skybox radius')
        gui.add(skybox, 'height',1,200,0.1).name('skybox height')
        scene.add(skybox)
        
    } 
        else {
            scene.remove(skybox)
            
        } 
    console.log('bg changed');
    updateAllMaterials()
}


/**
 * Environment map
 */

gui.add(numberMap,'mapnum').min(0).max(2).step(1).onChange(UpdateBackground)
let objectsToIntersect = []
const environmentMap = []
for (let i=0;i<4;i++) {
    environmentMap[i] = hdriLoader.load('/textures/environmentMap/0'+(i+1)+'_Cam.hdr',(EnvironmentMap)=>
{ 
    environmentMap[i].mapping= THREE.EquirectangularReflectionMapping
    environmentMap[i].colorSpace = THREE.SRGBColorSpace
})
}     
    scene.environment= environmentMap [numberMap['mapnum']]  
    debugObject.envMapIntensity = 2.5
    debugObject.backgroundIntensity = 1.4
    scene.backgroundIntensity = 1.4
    debugObject.backgroundBlurriness = 0
    //debug
    gui.add(debugObject,'envMapIntensity').min(0).max(10).step(0.001).onChange(updateAllMaterials)
    gui.add(debugObject,'backgroundBlurriness').min(-1).max(1).step(0.001).onChange(updateAllMaterials)
    gui.add(debugObject,'backgroundIntensity').min(-1).max(10).step(0.001).onChange(updateAllMaterials)
/**
 * Models
 */

// let model2 = null
// gltfLoader.load(
//     '/models/world_earth/scene.gltf',
//     (gltf) =>
//     {   
//         gltf.scene.scale.set(1.3, 1.3, 1.3)
//         model2 = gltf.scene
//         model2.position.set(50,6,-0.8)
//         // model2.rotation.y = 
//         objectsToIntersect.push(model2)
//         model2.visible=false       
//         updateAllMaterials()
//         gui.add(model2.position,'x',0,100,0.001).name('globe posi x')
//         gui.add(model2.position,'y',0,100,0.001).name('globe posi y')
//     }
// )
let fox=null
let mixer = null 
gltfLoader.load(
    '/models/Fox/glTF/Fox.gltf',
    (gltf)=>{
        fox = gltf.scene
        mixer = new THREE.AnimationMixer(fox)
        const action = mixer.clipAction(gltf.animations[0])
        action.play()
        fox.scale.set(0.025,0.025,0.025)
        fox.position.set(28,-0.7,-5.7)
        fox.rotation.y=-0.8
        scene.add(fox)
        gui.add(fox.position,'z',-100,100,0.001).name('fox posi z')
        gui.add(fox.rotation,'x',-100,100,0.001).name('fox rota x')
        gui.add(fox.rotation,'z',-100,100,0.001).name('fox rota z')
        gui.add(fox.rotation,'y',-100,100,0.001).name('fox rota y')
        fox.visible=false
        
    },
    ()=>{
        console.log('progress');
    },
    ()=>{
        console.log('error');
    },
)       
       

let model2 = null
gltfLoader.load(
    '/models/globe2.gltf',
    (gltf) =>
    {   
        model2 = gltf.scene
        model2.scale.set(0.5,0.5,0.5)
        model2.position.set(28,-0.7,-0.8)
        // model2.rotation.y = 
        objectsToIntersect.push(model2)
        model2.visible=false       
        updateAllMaterials()
        gui.add(model2.position,'x',-100,100,0.001).name('globe posi x')
        gui.add(model2.position,'y',-100,100,0.001).name('globe posi y')
        gui.add(model2.position,'y',-100,100,0.001).name('globe posi y')
        gui.add(model2.position,'y',-100,100,0.001).name('globe posi y')
        gui.add(model2.position,'y',-100,100,0.001).name('globe posi y')
    }
)

/*
*Arrows
*/


const textureLoader = new THREE.TextureLoader()
const arrowTexture = textureLoader.load('/textures/white-arrow.png')
const material = new THREE.MeshStandardMaterial(
    {
        side:THREE.DoubleSide,
        map:arrowTexture,
        transparent:true
    }
)
const arrow1 = new THREE.Mesh(
    new THREE.PlaneGeometry(2,2,10,100),
    material
    )

arrow1.position.set(11.5,0,-0.8)
arrow1.rotation.set(1.6,0,-1.6)  
objectsToIntersect.push(arrow1)
scene.add(arrow1)  

const raycaster = new THREE.Raycaster()
const arrowRaycaster = new THREE.Raycaster()
const modelRaycaster = new THREE.Raycaster()

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
directionalLight.castShadow = true
directionalLight.shadow.camera.far = 15
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.normalBias = 0.05
directionalLight.position.set(0.25, 3, - 2.25)
scene.add(directionalLight)

/**
 * sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 2.5, 0)
// camera.lookAt(11.5,3,-0.8)
// scene.add(camera)


//aimbox
const aimboxMaterial = new THREE.MeshBasicMaterial({
    color:'#ff0000',
}
)
const aimboxGeometry= new THREE.BoxGeometry(1,1,1) 
aimboxMaterial.visible=false   
const aimbox = new THREE.Mesh(aimboxGeometry,aimboxMaterial)
aimbox.scale.set(0.1,0.1,0.1)
aimbox.position.set(11.5,3,-0.8)
scene.add(aimbox)
camera.lookAt(aimbox.position)
const cameraGroup = new THREE.Group() 
cameraGroup.add(camera)
scene.add(cameraGroup)
gui.add(camera.position,'y',0,10,0.01).name('camera y')
// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.useLegacyLights = false
renderer.toneMapping = THREE.ReinhardToneMapping
renderer.toneMappingExposure = 3
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Scroll
 */
window.addEventListener('wheel',(event)=>
{   if(((camera.fov+ event.deltaY * 0.05) < 135) && ((camera.fov+ event.deltaY * 0.05 ) > 10)){
    camera.fov += event.deltaY * 0.05
    console.log(camera.fov);
    camera.updateProjectionMatrix();    
}      
})

/**
 * Mouse Cursor
 */
const mouse = new THREE.Vector2()
window.addEventListener('mousemove',(event)=>{
    mouse.x = event.clientX/sizes.width * 2 -1
    mouse.y = -(event.clientY/sizes.height * 2 -1)
})

/*
* click event
*/
let arrow1IsActive = false
let globeIsActive = false
let globeturns = 1
let continueIsActive=false
window.addEventListener('click',(event)=>{
    console.log('click');
    switch (numberMap['mapnum']) {
        case 0:
            if(arrow1IsActive)
            {
                scene.remove(model2)
                model2.visible=false
                console.log('animacion A2');
                moveToSphere2()
            }
            break;
        case 1:
            if(arrow1IsActive)
            {
                scene.add(model2)
                model2.visible=true
                fox.visible=true
                movieScreen.visible=true
                console.log('animacion A3');
                moveToSphere3()
            }
            break;
        case 2:
            if(arrow1IsActive)
            {
                scene.remove(model2)
                model2.visible=false
                console.log('animacion A1');
                moveToSphere1()
            }else if(globeIsActive){
                globeturns = - globeturns
                model2.children[0].children[0].position.y +=3
                console.log(model2);
            // }else if(continueIsActive){
            //     scene.remove(model2)
            //     model2.visible=false
            //     moveToSphere4()
            }

            break;      
        default:
            break;
    }

}) 

/*
*alphavideo
*/
        const video = document.getElementById('video')     
        video.volume = 0.3
        const videoTexture = new THREE.VideoTexture(video)
        videoTexture.needsUpdate = true
        videoTexture.minFilter = THREE.LinearFilter
        videoTexture.magFilter = THREE.LinearFilter

        
        const movieMaterial = new THREE.MeshBasicMaterial({
            transparent:true,
            map:videoTexture,
            side: THREE.FrontSide,
            toneMapped:false
        })

        
        const movieGeometry = new THREE.PlaneGeometry(5.4,3.6)
        const  movieScreen = new THREE.Mesh(movieGeometry,movieMaterial)
        // movieScreen2 = new THREE.Mesh(movieGeometry2,movieMaterial2)
        scene.add(movieScreen)
        movieScreen.visible=false
        movieScreen.position.set(7,1,2.3)
        movieScreen.rotation.y=4.59
        gui.add( movieScreen.position,'x',0,30,0.001).name('videposition x')
        gui.add( movieScreen.position,'z',0,30,0.001).name('videposition z')
        gui.add( movieScreen.position,'y',0,30,0.001).name('videposition y')
        gui.add( movieScreen.rotation,'y',0,6,0.001).name('viderotation')




const videos = [
    {
        position: new THREE.Vector3(20,2,0),
        element: document.querySelector('.video'),
    }
]
/*
*Change Spheres
*/

let animationsFinished = true 

const moveToSphere2 = ()=>{
    animationsFinished= false 
    numberMap['mapnum']=1
    UpdateBackground()
    arrow1.scale.set(0,0,0)
    gsap.to(
        arrow1.scale,{
            duration: 0.5,
            ease: 'power2.inOut',
            x: '+=1',
            z: '+=1',
            y: '+=1',  
            onComplete: function(){
                animationsFinished= true
            }    
        })
   
}
const moveToSphere3 = ()=>{  
    numberMap['mapnum']=2
    UpdateBackground()
    camera.position.y=1
    arrow1.scale.set(0,0,0)
    animationsFinished= true
   
}
const moveToSphere1 = ()=>{  
    numberMap['mapnum']=0
    UpdateBackground()
    animationsFinished= false
    arrow1.scale.set(0,0,0)
    gsap.to(
        arrow1.scale,{
            duration: 0.5,
            ease: 'power2.inOut',
            x: '+=1',
            z: '+=1',
            y: '+=1',  
            onComplete: function(){
                animationsFinished= true
            }    
        })
}
const moveToSphere4 =()=>{
    
    animationsFinished= false
    arrow1.scale.set(0,0,0)
    gsap.to(
        aimbox.position,{
            duration: 1.6,
            ease: 'power2.inOut',
            z: '+=8',
            x: '-=8',
            onComplete: function(){
                aimbox.position.set(11.5,3,-0.8)
                numberMap['mapnum']=3
                UpdateBackground()
                animationsFinished= true
            }    
        })
    // gsap.to(
    //   camara .scale,{
    //         duration: 0.5,
    //         ease: 'power2.inOut',
    //         x: '+=1',
    //         z: '+=1',
    //         y: '+=1',  
    //         onComplete: function(){
    //             animationsFinished= true
    //         }    
    //     })
    animationsFinished= true
}

/**
 * PARALAX
 */
let cursor = {}
const setParalax = ()=>{       
    cursor.x = 0
    cursor.y = 0

    window.addEventListener('mousemove',(event)=>
    {
        cursor.x = (event.clientX / sizes.width) -0.5
        cursor.y = (event.clientY /sizes.height) -0.5       
    })
    }
setParalax()

/**
 * Animate
 */



    let start = Date.now()
    let current = start
    let elapsed = 0
    let delta = 16

    const clock = new THREE.Clock()
    let previousTime = 0



const tick = () =>
{
    camera.updateProjectionMatrix();
    
    if(sceneReady){
        video.play()
        scene.remove(overlay)
        console.log(cursor.x);
        /**
         * UPDATE PARALAX  
        */
        camera.lookAt(aimbox.position)
        const currentTime = Date.now()
        delta = currentTime - current
        current = currentTime
        let parallaxX = - cursor.x
        let parallaxY =   cursor.y
        let  newPositionX = (parallaxX - cameraGroup.position.z) * delta * 0.01
        let newPositionY = (parallaxY - cameraGroup.position.y) * delta * 0.01
        cameraGroup.position.z +=  newPositionX
        cameraGroup.position.y += newPositionY

        if(animationsFinished){
             /**
         * RAYCASTER 
         */
          //raycaster for arrows && objects 
          arrowRaycaster.setFromCamera(mouse,camera)
        //   model2.rotation.y += 0.01* globeturns 
                        
          const objectIntersects = arrowRaycaster.intersectObjects(objectsToIntersect)
          for (const object of objectsToIntersect) {
            if(object!=arrow1){
                globeIsActive=false
            }else
            {  
                if( numberMap['mapnum']==2||numberMap['mapnum']==3){
                    object.scale.set(0,0,0)
                }else{object.scale.set(1,1,1)}
                
                arrow1IsActive=false    
            }       
                 arrow1IsActive=false
                 document.getElementsByTagName('body')[0].style.cursor = 'default'
                 
          }
          for (const objectIntersect of objectIntersects) {
             if(objectIntersect.object!=arrow1){
                 globeIsActive=true
             }else
             {  
               
                 objectIntersect.object.scale.set(1.3,1.3,1.3)
                 arrow1IsActive=true
                 
             }
             document.getElementsByTagName('body')[0].style.cursor = 'pointer'
          }
          document.querySelector('.continue').classList.remove('visible') 
          
          if(numberMap['mapnum']==2){
            if(cursor.x>0.45){
                document.querySelector('.continue').classList.add('visible')
                document.getElementsByTagName('body')[0].style.cursor = 'pointer'
                continueIsActive=true
              }
          }
        }
       
    }

    //fox
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime


   //update mixer
   if(mixer)mixer.update(deltaTime)
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

}

tick()